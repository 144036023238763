<template>
  <div class="row justify-content-center text-center mt-5">
    <div class="col-md-5">
      <b-alert variant="info" :show="loggedOut">
        Je bent uitgelogd.<br />
        <router-link to="login">Klik hier om opnieuw in te loggen.</router-link>
      </b-alert>
      <b-card header="Uitloggen" v-if="!loggedOut">
        <p>Weet je zeker dat je wil uitloggen?</p>
        <p><b-button variant="info" @click="logout">Uitloggen</b-button></p>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loggedOut: false
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout")
      this.loggedOut = true
    }
  }
}
</script>